import { useEffect, useRef, useState } from "react";
import { NavButton } from "./NavButton";
import { Navbar } from "./Navbar";

export const Header = () => {
  const [padding, setPadding] = useState(10);
  const headerRef = useRef(null);
  const timeoutRef = useRef(null); // For managing timeouts

  const setHeight = () => {
    const header = headerRef.current;
    const home = document.getElementById("home");
    if (header && home) {
      const headerHeight = header.clientHeight;
      home.style.height = `calc(100vh - ${headerHeight}px - 1px)`;
    }
  };

  const handleScroll = () => {
    const newPadding = window.pageYOffset > 0 ? 4 : 10;
    setPadding(newPadding);
    // Clear existing timeout and set a new one
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(setHeight, 10);
  };

  useEffect(() => {
    handleScroll(); // Set initial state based on current scroll position
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timeoutRef.current); // Cleanup timeout on unmount
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        id="header"
        ref={headerRef}
        className={`sticky top-0 text-primary bg-bg-primary p-0 flex justify-center items-center border-b z-40`}
      >
        <div
          id="logo"
          className={`HeadingFont text-4xl py-${padding} px-4 md:px-20 md:border-r transition-all duration-500`}
        >
          {padding === 10 ? "Devster Labs" : "D"}
        </div>
        <div
          id="nav-btn"
          className="px-4 md:px-20 w-full flex items-center justify-end"
        >
          <NavButton />
        </div>
      </div>
      <Navbar />
    </>
  );
};
