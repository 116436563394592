import { useTeams } from "../hooks";

export const Team = () => {
  const data = useTeams();
  return (
    <div
      id="our team"
      className="flex flex-col items-center justify-center gap-20 p-4 md:p-20 h-full border-b"
    >
      <h2 className="w-full max-w-[960px] HeadingFont text-2xl md:text-6xl text-center md:mb-8">
        Meet the Team
      </h2>
      <div className="w-full flex flex-wrap max-w-[960px] justify-center relative">
        <div className="h-[77px] w-[1px] bg-primary absolute top-[-77px] hidden md:block"></div>
        {data &&
          data.map((member, index) => (
            <div
              className={`group w-full max-w-[320px] p-3 flex flex-col items-center justify-start ${
                [1, 3, 4, 5].includes(index) && "md:border md:border-b-0"
              }`}
              key={member._id}
            >
              <img
                className="w-full filter grayscale"
                src={member.image}
                alt={member.name}
              />
              <div className="flex flex-col items-start justify-start w-full gap-1 p-2 group-hover:bg-bg-secondary">
                <h3 className="HeadingFont">{member.name}</h3>
                <p className="RobotoThin">{member.position}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
