import { useState } from "react";
import {
  Ellipse,
  Group21,
  Laptop,
  Left,
  LeftHover,
  MatrixSVG,
  ReviewsSvg,
  Right,
  RightHover,
  Target,
  TerminalSvg,
} from "../images";
import { SwitchIcon } from "./common";
import { useReviews } from "../hooks";

export const Reviews = () => {
  const data = useReviews();
  const [url, setUrl] = useState(0);
  const nextUrl = () => {
    document.getElementById("next").src = RightHover;
    setTimeout(() => {
      document.getElementById("next").src = Right;
    }, 100);
    if (url === data.length - 1) {
      return setUrl((p) => 0);
    }
    setUrl((prev) => prev + 1);
  };
  const prevUrl = () => {
    document.getElementById("prev").src = LeftHover;
    setTimeout(() => {
      document.getElementById("prev").src = Left;
    }, 100);
    if (url === 0) {
      return setUrl((prev) => data.length - 1);
    }
    setUrl((prev) => prev - 1);
  };
  return (
    <div
      id="our work"
      className="hidden lg:flex w-full items-start justify-start"
    >
      <div className="hidden md:flex w-full max-w-[533px] h-[560px] items-center justify-center border-b overflow-hidden relative">
        <img className="w-full max-w-[487px]" src={MatrixSVG} alt="" />
        <img
          className="w-full max-w-[45%] absolute top-16 right-0 animate-floatUp"
          src={Group21}
          alt=""
        />
        <img
          className="w-full max-w-[30%] absolute top-20 right-4 animate-floatUp"
          src={Ellipse}
          alt=""
        />
        <img
          className="w-full max-w-[30%] absolute top-50 right-4 animate-floatUpSlow"
          src={Ellipse}
          alt=""
        />
        <img
          className="w-full max-w-[80%] absolute bottom-0 right-0"
          src={ReviewsSvg}
          alt=""
        />
        <img
          className="w-full max-w-[25%] absolute top-16 left-24 animate-floatUp"
          src={TerminalSvg}
          alt=""
        />
        <span className="absolute text-4xl top-24 left-36 animate-floatUp-opacity">
          _
        </span>
        <SwitchIcon className="w-full max-w-[15%] absolute bottom-40 right-20" />
      </div>
      <div className="relative flex flex-col items-center justify-center w-full p-4 md:p-20 min-h-[560px] md:border-l border-b overflow-hidden">
        <img
          src={Target}
          alt=""
          className="animate-float hidden md:block w-[75px] absolute left-[75px] top-[75px] -z-50"
        />
        {/* <p className="RobotoThin text-xl mb-16">
          Embark on a journey through the deeply personal narratives and
          illuminating testimonials generously shared by our cherished clients.
          These testimonials serve as powerful vignettes, offering profound
          insights into the remarkable value and unparalleled quality of our
          services, enriching both our understanding and appreciation of the
          profound impact we have on those we serve.
        </p> */}
        <div className="w-full relative lg:w-[680px]">
          <img src={Laptop} alt="..." className="w-full" />
          <iframe
            src={data[url]?.url}
            frameborder="0"
            title="Test"
            className="absolute left-[12.5%] top-[6.4%] w-[75.4%] h-[330px]"
          ></iframe>
        </div>
        <div className="absolute top-[24px] right-[24px] w-[320px] h-[140px]">
          <div
            className="absolute bg-[#212227] border-[#fff] w-[40px] h-[40px] bottom-0 left-[40px]"
            style={{ transform: "rotate(45deg)" }}
          ></div>
          <div className="absolute border border-[#fff] bg-[#212227] w-full h-[130px] rounded-xl p-5 text-sm flex flex-col items-start justify-center">
            <span className="font-bold mb-1 text-[#4BADCC]">
              {data[url]?.client_name}
            </span>
            <p className="mb-1">{data[url]?.comment}</p>
            <span className="text-yellow-300">
              {Array(data[url]?.rating || 0)
                .fill("")
                .map(() => "★ ")}
            </span>
          </div>
        </div>
        <img
          onClick={prevUrl}
          id="prev"
          src={Left}
          alt="<"
          className="absolute w-[200px] -left-[100px] cursor-pointer"
        />
        <img
          onClick={nextUrl}
          id="next"
          src={Right}
          alt=">"
          className="absolute w-[200px] -right-[100px] cursor-pointer"
        />
        {/* <a href="/" className="HeadingFont text-2xl md:text-4xl underline">
          Learn More
          </a> */}
      </div>
    </div>
  );
};
