import { useEffect, useState } from "react"
import { BASE_URL } from "../common";

export const useClients = () => {
    const [data, setData] = useState([]);
    const getClients = async () => {
        const res = await fetch(BASE_URL + "/client").then(res => res.json());
        setData(() => [...res]);
    }
    useEffect(() => {
        getClients();
    }, [])
    return data;
}