import { useClients } from "../hooks";

export const AutoCarousal = () => {
  const data = useClients();
  return (
    <div className="p-[3%] border-t border-b w-full overflow-hidden relative">
      <div className="flex animate-scroll">
        {data &&
          data.map((client) => (
            <img
              key={client?._id}
              src={client?.image}
              alt={client?.title}
              className="w-full h-[80px] filter grayscale mx-12"
            />
          ))}
      </div>
    </div>
  );
};
