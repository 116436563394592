import { MenuButton } from "../../images";

export const toggleNav = () => {
  const navbar = document.getElementById("navbar");
  const navbarb = document.getElementById("navbarb");
  if (navbar) {
    if (navbar.classList.contains("top-0")) {
      navbar.classList.remove("top-0");
      navbarb.classList.remove("top-0");
      navbar.classList.add("-top-[100vh]");
      navbarb.classList.add("-top-[100vh]");
    } else {
      navbar.classList.remove("-top-[100vh]");
      navbarb.classList.remove("-top-[100vh]");
      navbar.classList.add("top-0");
      navbarb.classList.add("top-0");
    }
  }
};
export const NavButton = () => {
  return (
    <button onClick={toggleNav}>
      <img className="w-[55px]" src={MenuButton} alt="MenuButton" />
    </button>
  );
};
