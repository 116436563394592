import { useServices } from "../hooks";

const NavLink = ({ index, children }) => {
  return (
    <a
      className="transition6 relative HeadingFont px-4 md:px-20 py-4 text-lg md:text-7xl flex items-center justify-start gap-8 w-full md:w-auto md:gap-20 hover:text-secondary group hover:ml-8"
      href="/"
    >
      <span className="text-lg RobotoRegular group-hover:text-primary">0{index + 1}</span>
      {children}
    </a>
  );
};

export const Services = () => {
  const services = useServices();
  return (
    <div
      id="services"
      className="w-full border-b flex flex-col items-start justify-start gap-10 py-8 overflow-hidden overflow-y-scroll"
    >
      {services && services.map((i, index) => (
        <NavLink index={index} key={i}>{i}</NavLink>
      ))}
    </div>
  );
};
