import { useEffect } from "react";

export const BottomRightSignal = ({ width = 550, style, className }) => {
  let toggle = false;
  const resizeCircle = () => {
    if (toggle) {
      document.getElementById("l").setAttribute("r", width / 2 - 1);
      document.getElementById("m").setAttribute("r", width / 2.5);
      document.getElementById("s").setAttribute("r", width / 4);
    } else {
      document.getElementById("l").setAttribute("r", width / 2 / 1.1);
      document.getElementById("m").setAttribute("r", width / 2.5 / 1.1);
      document.getElementById("s").setAttribute("r", width / 4 / 1.1);
    }
    toggle = !toggle;
  };
  useEffect(() => {
    const beatInterval = setInterval(() => {
      resizeCircle();
    }, 1000);
    return () => {
      clearInterval(beatInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div style={style} className={className + " -z-50"}>
      <svg
        width={width}
        height={width}
        viewBox={`0 0 ${width} ${width}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          id="l"
          cx={width / 2}
          cy={width / 2}
          r={width / 2 - 1}
          stroke="white"
          strokeWidth="2"
          className="transition6"
        />
        <circle
          id="m"
          cx={width / 2}
          cy={width / 2}
          r={width / 2.5}
          stroke="white"
          strokeWidth="2"
          className="transition6"
        />
        <circle
          id="s"
          cx={width / 2}
          cy={width / 2}
          r={width / 4}
          stroke="white"
          className="transition6"
          strokeWidth="2"
        />
        <circle cx={width / 2} cy={width / 2} r={width / 8} fill="#FFF" />
        <circle cx={width / 2} cy={width / 2} r={width / 50} fill="#212227" />
      </svg>
    </div>
  );
};
