import { useEffect, useState } from "react";
import { LogoFull } from "../images";

export const Loading = () => {
  const [dots, setDots] = useState(".");
  let i = 10;
  useEffect(() => {
    const writter = setInterval(() => {
      if (i > 0) {
        setDots((prev) => prev + ".");
        i--;
      }
    }, 500);
    return () => {
      clearInterval(writter);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
    useEffect(() => {
      setTimeout(() => {
          const left = document.getElementById("left-load");
          const right = document.getElementById("right-load");
          const loading = document.getElementById("loading");
          if(left){
              if(left.classList.contains("left-0")){
                  left.classList.remove("left-0");
                  left.classList.add("-left-[100%]");
              }
              if(right.classList.contains("right-0")){
                  right.classList.remove("right-0");
                  right.classList.add("-right-[100%]");
              }
              if(loading.classList.contains("top-0")){
                loading.classList.remove("top-0");
                loading.classList.add("-top-[100%]");
            }
          }
      }, 3000)
    }, [])
  return (
    <div id="loading" className="fixed top-0 left-0 w-full h-[100vh] z-50">
      <div
        id="left-load"
        className="fixed top-0 left-0 w-[50%] h-[100vh] bg-[#212121] z-50 flex items-center justify-center overflow-hidden flex-col"
        style={{ transition: "left 1s" }}
      >
        <div className=" absolute -right-[75px] w-[150px] text-center flex items-center justify-center flex-col">
          <svg
            width={240}
            height={240}
            viewBox={`0 0 ${240} ${240}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute"
          >
            <circle
              id="l"
              cx={240 / 2}
              cy={240 / 2}
              r={240 / 2 - 1}
              stroke="white"
              strokeWidth="2"
              className="transition6"
            />
          </svg>
          <img src={LogoFull} alt="D" className="w-full mb-2" />
          <h1 className="HeadingFont text-xl text-[#4BADCC]">{dots}</h1>
        </div>
      </div>
      <div
        id="right-load"
        className="fixed top-0 right-0 w-[50%] h-[100vh] bg-[#212121] z-50 flex items-center justify-center overflow-hidden flex-col"
        style={{ transition: "right 1s" }}
      >
        <div className=" absolute -left-[75px] w-[150px] text-center flex items-center justify-center flex-col">
          <svg
            width={240}
            height={240}
            viewBox={`0 0 ${240} ${240}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute"
          >
            <circle
              id="l"
              cx={240 / 2}
              cy={240 / 2}
              r={240 / 2 - 1}
              stroke="#4BADCC"
              strokeWidth="2"
              className="transition6"
            />
          </svg>
          <img src={LogoFull} alt="D" className="w-full mb-2" />
          <h1 className="HeadingFont text-xl text-white">{dots}</h1>
        </div>
      </div>
    </div>
  );
};
