import { useEffect, useState } from "react";
import { BASE_URL } from "../common";

export const useTeams = () => {
  const [data, setData] = useState([]);
  const getData = async () => {
    const res = await fetch(BASE_URL + "/team").then((res) => res.json());
    setData(() => [...res]);
  };
  useEffect(() => {
    getData();
  }, []);
  return data;
};
