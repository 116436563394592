import { Triangle } from "../images";
import { BottomRightSignal } from "./BottomRightSignal";

export const Home = () => {
  return (
    <div
      id="home"
      className="w-full overflow-x-hidden relative flex items-center justify-start p-4 md:p-20"
    >
      <div className="w-full max-w-[650px]">
        <h1 className="uppercase text-2xl font-bold HeadingFont mb-8 md:mb-4">
          <span className="md:text-7xl">Gateway to</span>{" "}
          <br className="hidden md:inline-block" />
          <span className="text-secondary md:text-3xl">Pre-Screened</span>{" "}
          <span className="hidden md:inline-block text-primary tracking-tighter RobotoThin">
            -----------
          </span>{" "}
          <span className="text-secondary md:text-3xl">Full-Time</span>{" "}
          <br className="hidden md:inline-block" />
          <span className="md:text-4xl">Software Developers</span>
        </h1>
        <p className="RobotoThin text-2xl">
          Revamp outsourcing with global collaboration, elite developers, and
          cost-effective integration.
        </p>
      </div>
      <img src={Triangle} alt="Devster Labs" className="hidden md:block absolute right-2 w-56" />
      <BottomRightSignal
        width={300}
        className={"absolute hidden md:block -right-[150px]"}
      />
    </div>
  );
};
