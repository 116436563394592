export const Contact = () => {
  return (
    <div id="contact" className="flex flex-col items-center justify-center w-full h-screen">
      <h4 className="w-full max-w-[720px] HeadingFont text-3xl md:text-8xl text-center mb-8">
        Your future, together.
      </h4>
      <p className="w-full max-w-[520px] RobotoThin text-xl text-center mb-8">
        We'd love to help bring your next idea to life. <br />
        Schedule a time with our founders.
      </p>
      <a className="HeadingFont text-2xl md:text-3xl bg-secondary p-6 rounded-full" href="https://calendly.com/abdulraheem-arhex/devster-labs">Schedule a Call</a>
    </div>
  );
};
