import { useState } from "react";
import { BASE_URL } from "../common";

export const useSubscribe = () => {
  const [email, setEmail] = useState("");
  const updateEmail = (e) => {
    setEmail(() => e.target.value);
  };
  const handleSubscribe = async (e) => {
    e.preventDefault();
    await fetch(BASE_URL + "/subscribers", {
      method: "POST",
      body: JSON.stringify({ email }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.ok) {
        alert("Subscribed to newsletter");
      }
    });
  };
  return { email, updateEmail, handleSubscribe };
};
