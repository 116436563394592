export const Modal = ({ show, onClose, children }) => {
  if (!show) {
    return null;
  }

  return (
    <div
      className="fixed top-0 left-0 right-0 bottom-0 bg-[#000c] flex justify-center items-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-[#1e1e1e] p-[20px] rounded-md relative w-[80%] max-w-[500px] text-white max-h-[80%] overflow-hidden overflow-y-scroll"
        style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="fixed top-[10px] right-[10px] bg-none border-none text-white cursor-pointer"
          style={{ fontSize: "20px" }}
          onClick={onClose}
        >
          X
        </button>
        {children}
      </div>
    </div>
  );
};
