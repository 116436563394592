import { FaEnvelope, FaLinkedin, FaPhone } from "react-icons/fa";
import { useSubscribe } from "../../hooks";
import { LogoFull } from "../../images";

export const Footer = ({ openModal }) => {
  const { email, updateEmail, handleSubscribe } = useSubscribe();
  return (
    <div
      id="footer"
      className="sticky top-0 text-primary bg-bg-primary p-0 flex flex-col md:flex-row md:justify-center md:items-center border-t z-40"
    >
      <div
        id="logo"
        className={`HeadingFont text-4xl py-4 px-4 md:px-20 md:border-r transition6`}
      >
        <img src={LogoFull} alt="Devster" className={`w-[160px]`} />
      </div>
      <div
        id="nav-btn"
        className="px-4 md:px-20 w-full flex flex-col md:flex-row gap-4 md:items-center md:justify-between mb-4 md:mb-0"
      >
        <span className="HeadingFont text-2xl max-w-[200px]">
          Sign up to our newsletter
        </span>
        <form
          onSubmit={handleSubscribe}
          className="border w-full max-w-[420px] rounded-full p-2 flex gap-3"
        >
          <input
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={updateEmail}
            className="border-none bg-transparent w-full rounded-full p-1 px-3"
            placeholder="Type Email..."
          />
          <button className="p-1 px-3 bg-secondary rounded-full">
            Subscribe
          </button>
        </form>
        <div className="flex justify-end items-center gap-3">
          <a
            href="tel:+923331729966"
            target="_blank"
            rel="noreferrer"
            className="flex justify-start items-center gap-2"
          >
            <FaPhone />
          </a>
          <a
            href="mailto:info@devsterlabs.com"
            target="_blank"
            rel="noreferrer"
            className="flex justify-start items-center gap-2"
          >
            <FaEnvelope />
          </a>
          <a
            href="https://www.linkedin.com/company/devster-labs"
            target="_blank"
            rel="noreferrer"
            className="flex justify-start items-center gap-2"
          >
            <FaLinkedin />
          </a>
          <button onClick={openModal}>Privacy Policy</button>
        </div>
      </div>
    </div>
  );
};
