import { useEffect, useState } from "react";
import { BASE_URL } from "../common";

export const useServices = () => {
  const [data, setData] = useState([]);
  const getData = async () => {
    const res = await fetch(BASE_URL + "/services").then((res) => res.json());
    setData(() => res.map(i => i?.title));
  };
  useEffect(() => {
    getData();
  }, []);
  return data;
};
