import { AboutSVG, Code, GraphArrow, MatrixSVG, Radar, Rectangle3D, Square3D, Target } from "../images";

export const About = () => {
  return (
    <div id="about" className="flex flex-col md:flex-row w-full items-start justify-start">
      <div className="relative flex flex-col items-center justify-center w-full p-4 md:p-20 min-h-[560px] md:border-r border-b">
        <img src={Target} alt="" className="animate-float hidden md:block w-[75px] absolute left-[75px] top-[75px] -z-50" />
        {/* <p className="RobotoThin text-xl mb-4">
          We connect startups and corporates with top-tier developers in
          Pakistan, ensuring a seamless integration of high quality technical
          expertise into your team.
        </p> */}
        <p className="HeadingFont block text-center text-3xl md:text-7xl mb-3 absolute uppercase text-[#4BADCC11]">Our Mission</p>
        {/* <ul className="RobotoThin text-xl list-disc ml-5 md:ml-3 mb-8">
            <li>High Quality Talent</li>
            <li>Seamless Team Integration</li>
            <li>Tailored Developer Solutions</li>
            <li>Cost-effective Solutions</li>
        </ul> */}
        <img className="animate-floatUpSlow w-[80%] lg:w-[540px] absolute" src={AboutSVG} alt="Hi" />
        {/* <a href="/" className="HeadingFont text-2xl md:text-4xl underline">View Team</a> */}
      </div>
      <div className="hidden md:flex w-full md:max-w-[533px] min-h-[560px] items-center justify-center border-b overflow-hidden relative">
        <img className="w-full opacity-65 max-w-[91.36960600375235%]" src={MatrixSVG} alt="" />
        <img className="w-full opacity-65 max-w-[75.046904315197%] absolute top-5 animate-float" src={Code} alt="" />
        <img className="w-full opacity-65 max-w-[43.151969981238274%] absolute -bottom-20 -left-10 animate-float" src={Rectangle3D} alt="" />
        <img className="w-full opacity-65 max-w-[14.071294559099437%] absolute bottom-16 right-24 animate-float2" src={Square3D} alt="" />
        <img className="w-full opacity-65 max-w-[7.879924953095685%] absolute bottom-[16.885553470919323%] right-[19.324577861163228%] animate-float2-rotate" src={Radar} alt="" />
        <img className="w-full opacity-65 max-w-[14.071294559099437%] absolute bottom-28 left-20 animate-float-rotate" src={GraphArrow} alt="" />
      </div>
    </div>
  );
};
