import { useState } from "react";
import "./App.css";
import {
  About,
  AutoCarousal,
  Contact,
  Footer,
  Header,
  Home,
  Loading,
  PrivacyPolicy,
  Reviews,
  Services,
  Team,
} from "./components";

function App() {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  return (
    <div>
      <Header />
      <Home />
      <AutoCarousal />
      <About />
      <Services />
      <Reviews />
      <Team />
      <Contact />
      <Footer openModal={openModal} />
      <PrivacyPolicy showModal={showModal} closeModal={closeModal} />
      <Loading />
    </div>
  );
}

export default App;
