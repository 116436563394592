import { Signal } from "../../images";
import { NavButton, toggleNav } from "./NavButton";

const links = ["About", "Services", "Our Work", "Our Team", "Contact"];

const NavLink = ({ index, children }) => {
  return (
    <a
      className="transition-all relative HeadingFont px-4 md:px-20 py-4 text-lg md:text-6xl flex items-center justify-start gap-4 w-full md:w-auto md:gap-20 hover:text-secondary group"
      href={"#" + children.toLowerCase()}
      onClick={toggleNav}
    >
      <span className="text-lg RobotoRegular transition-all">0{index + 1}</span>
      {children}
      <div className="md:flex hidden items-center justify-center">
        <img
          src={Signal}
          alt="Signal"
          className="hidden absolute w-20 group-hover:block transition-all -right-10"
        />
      </div>
    </a>
  );
};

export const Navbar = () => {
  return (
    <>
      <div
        id="navbarb"
        className="fixed -top-[100vh] left-0 w-full h-screen py-10 bg-secondary z-50"
      ></div>
      <div
        id="navbar"
        className=" border-b border-b-secondary fixed -top-[100vh] left-0 w-full h-screen bg-bg-primary flex flex-col justify-start items-start gap-3 z-50"
      >
        <div className="w-full p-2 px-4 md:px-20 flex items-center justify-end">
          <NavButton />
        </div>
        <div className="w-full flex items-start justify-start flex-col gap-3 h-[calc(100vh-71px-24px)] overflow-hidden overflow-y-visible">
          {links.map((i, index) => (
            <NavLink key={i} index={index}>
              {i}
            </NavLink>
          ))}
        </div>
      </div>
    </>
  );
};
