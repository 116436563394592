export const SwitchIcon = ({ className }) => {
  return (
    <svg
  viewBox="0 0 1037 422"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  className={className}
>
  <defs>
    <linearGradient id="blinkGradient">
      <stop offset="0%" stopColor="#fff" />
      <stop offset="50%" stopColor="#4BADCC" />
      <stop offset="100%" stopColor="#fff" />
    </linearGradient>
  </defs>
  <rect
    x="7"
    y="7"
    width="1023"
    height="408"
    rx="204"
    stroke="url(#blinkGradient)"
    strokeWidth="14"
    className="blink"
  />
  {/* <path
    d="M365 211C365 299.366 293.366 371 205 371C116.634 371 45 299.366 45 211C45 122.634 116.634 50.9999 205 50.9999C293.366 50.9999 365 122.634 365 211Z"
    fill="white"
  /> */}
  <circle className="leftRight" cx={220} cy={211} r={170} fill="#fff" stroke="#fff" />
</svg>

  );
};
